/* Home container */
.home-container {
  padding: 1rem;
  max-width: 100vw;
  margin: 0 auto;
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  text-align: center;
  animation: fadeIn 1.2s ease-in-out;
  color: #fff;
  margin-bottom: 2rem;
}

/* Website details section */
.website-details {
  background: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 85vw;
  margin: 3rem auto;
  margin-top: 0.4rem;
  color: #333;
  animation: slideInUp 1s ease-in-out;
}

.animated-title {
  font-size: 2.5rem;
  color: #3b82f6;
  margin-bottom: 1rem;
  animation: pulse 2s infinite;
}

.description {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 2rem;
}

/* How to use section */
.how-to-use {
  margin-top: 2rem;
}

.animated-subtitle {
  font-size: 2rem;
  color: #2563eb;
  margin-bottom: 1.5rem;
  animation: pulse 2s infinite;
}

.steps-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.step {
  background: #ffffff;
  border-radius: 15px;
  padding: 1.5rem;
  width: 45%;
  margin: 1rem 0;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.step:hover {
  transform: scale(1.05);
}

.step-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #3b82f6;
}

.step p {
  font-size: 1.2rem;
  color: #333;
}

/* Contact Form Section */
.contact-section {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 85vw;
  margin: 2rem auto;
  color: #333;
  animation: fadeInUp 1s ease-in-out;
}

.contact-title {
  font-size: 2rem;
  font-weight: 600;
  color: #3b82f6;
  margin-bottom: 1.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-label {
  font-size: 1rem;
  font-weight: 500;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.contact-form textarea {
  height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: #3b82f6;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
}

.submit-button:hover {
  background-color: #2563eb;
}

.success-message {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #4caf50;
}

.error-message {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #f44336;
}

/* About Me Section */
.about-me-section {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 85vw;
  margin: 2rem auto;
  color: #333;
  animation: fadeInUp 1s ease-in-out;
}

.about-me-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
}

.profile-photo {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-me-text {
  text-align: center;
}

.about-me-title {
  font-size: 2rem;
  font-weight: 600;
  color: #3b82f6;
  margin-bottom: 1rem;
}

.about-me-text p {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .website-details,
  .contact-section,
  .about-me-section {
    padding: 2rem;
    max-width: 90vw;
  }

  .animated-title {
    font-size: 2rem;
  }

  .description {
    font-size: 1.2rem;
  }

  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 90%;
  }

  .step-icon {
    font-size: 1.5rem;
  }

  .step p {
    font-size: 1rem;
  }

  .profile-photo {
    width: 120px;
    height: 120px;
  }

  .about-me-title {
    font-size: 1.8rem;
  }

  .about-me-text p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .website-details,
  .contact-section,
  .about-me-section {
    padding: 1.5rem;
    max-width: 95vw;
  }

  .animated-title {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1rem;
  }

  .steps-container {
    flex-direction: column;
  }

  .step {
    width: 100%;
    padding: 1rem;
  }

  .step-icon {
    font-size: 1.2rem;
  }

  .step p {
    font-size: 0.9rem;
  }

  .profile-photo {
    width: 100px;
    height: 100px;
  }

  .about-me-title {
    font-size: 1.6rem;
  }

  .about-me-text p {
    font-size: 0.9rem;
  }
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Highlight container for scholarship section */
.highlight-container {
  padding: 2rem;
  max-width: 86vw;
  margin: 0 auto;
  background: linear-gradient(135deg, #f97316, #f59e0b); /* Bright, bold colors */
  text-align: center;
  animation: zoomIn 1s ease-in-out;
  color: #fff;
  margin-bottom: 2.5rem;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 1.4rem;
}

.highlight-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  z-index: 0;
  animation: rotateBackground 10s linear infinite; /* Subtle background animation */
}

.highlight-container * {
  position: relative;
  z-index: 1;
}

.highlight-title {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 1.2rem;
  letter-spacing: 2px;
  animation: bounceIn 1s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.highlight-description {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 2rem;
  animation: fadeIn 1.2s ease-in-out;
  line-height: 1.6;
}

.explore-button {
  background-color: #ffffff;
  color: #f97316;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.explore-button:hover {
  background-color: #f59e0b;
  color: #ffffff;
  transform: scale(1.1); /* Hover zoom effect */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

/* Keyframes for additional animation */
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rotateBackground {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .highlight-container {
    padding: 1.5rem;
  }

  .highlight-title {
    font-size: 2.5rem;
  }

  .highlight-description {
    font-size: 1.3rem;
  }

  .explore-button {
    padding: 0.6rem 1.2rem;
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  .highlight-container {
    padding: 1rem;
  }

  .highlight-title {
    font-size: 2rem;
  }

  .highlight-description {
    font-size: 1rem;
  }

  .explore-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}
